.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.section-header h3 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  font-weight: 500;
  position: relative;
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}

#clients {
  padding: 60px 0;
}
#clients .clients-wrap {
  border-top: 1px solid #d6eaff;
  border-left: 1px solid #d6eaff;
  margin-bottom: 30px;
}

#clients .client-logo {
  padding: 64px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #d6eaff;
  border-bottom: 1px solid #d6eaff;
  overflow: hidden;
  background: #fff;
  height: 160px;
}

#clients img {
  transition: all 0.4s ease-in-out;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* CSS for partner name overlay */
.client-logo {
  position: relative;
  overflow: hidden;
}

.client-name {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background color */
  color: #fff; /* Text color */
  opacity: 0; /* Initially hidden */
  cursor: pointer;

  transition: opacity 0.3s ease-in-out;
}

.client-logo:hover .client-name {
  opacity: 1; /* Display on hover */
}
